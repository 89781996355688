<template>
	<div class="subMain">
		<!-- <Banner /> -->
		<div class="maxBox">
			<div class="subtabList">
				<ul>
					<li :class="{'active':active=='/culture/concept'}">
						<router-link to="/culture/concept">
							文化理念
							<span><el-icon><ArrowRightBold /></el-icon></span>
						</router-link>
					</li>
				</ul>
			</div>
			<div class="subContent">
				<div class="subPageTitle">
					<h4>{{ activeTitle }}</h4>
					<div class="breadcrumb">
						<el-breadcrumb :separator-icon="ArrowRight">
						    <el-breadcrumb-item :to="{ path: '/index' }">首页</el-breadcrumb-item>
						    <el-breadcrumb-item>公司文化</el-breadcrumb-item>
						    <el-breadcrumb-item>{{ activeTitle }}</el-breadcrumb-item>
						</el-breadcrumb>
					</div>
				</div>
				<router-view />
			</div>
		</div>
	</div>
</template>

<script setup>
	import { ref } from 'vue'
	import { useRoute } from 'vue-router'
	import { ArrowRight,ArrowRightBold } from '@element-plus/icons-vue'
	import Banner from '@/components/banner/Index.vue'
	const router = useRoute();
	const active = ref(router.path);
	const activeTitle = ref(router.meta.title);
</script>
<style lang="less">
  @import '@/assets/css/common';
</style>
<style lang="less" scoped>

</style>
